.dzStyleKapak{
    border: 'dashed 3px #eee';
    border-color: #eee;
    border-radius: 5px;
    padding-top: 30px;
    text-align: center;
    height: 277px;
    width:948px;
}

.dzStyleFoto{
    border: 'dashed 3px #eee';
    border-color: #eee;
    border-radius: 5px;
    padding-top: 30px;
    text-align: center;
    height: 250px;
    width:462px;
}