.modalContainer {
  padding: 0 px 0 px;
}

.ui.modal {
  width: 1000px;
}

.modalBodyContainer {
  margin: 15px 10px;
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif; */

.modalLine {
  margin-top: 5px;

  color: #F2F2F2;
  background-color: #F2F2F2;
  height: 5;
}

.modalHeader {
  display: flex;
  padding: 10px 25px 5px 15px;
  justify-content: space-between;
  align-items: center;
}

.modalTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.modalCloseButton {
  padding: 10px 10px;
  background-color: white;
}

.modalCloseButton:hover {
  background-color: #ebebeb;
}

.modalFooter {
  clear: both;
  margin-bottom: 20px;
}

.ui.modal>.content {
  padding: 0px;
}

.confirmModal.ui.modal>.content,
.RemoveConfirmModal.ui.modal>.content {
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  background: #fff;
}

.EntryHeader {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
  border-bottom: #969595 2px solid;

}

.selectedBorderBox {
  margin: auto auto auto 55px;
  border: #333 2px solid;
  border-top: none;
  border-radius: 0 0 5px 5px;
  width: 280px;

  transition: 2s opacity ease-in-out, 2s transform ease-in-out;
}

.selectedBorderBoxThumb {
  margin: auto auto auto 49px;
  border: #333 2px solid;
  border-top: none;
  border-radius: 0 0 5px 5px;
  width: 140px;

  transition: 2s opacity ease-in-out, 2s transform ease-in-out;
}
#restingPlacePhoneNumber{
  text-decoration: underline;
}
.serviceTable td{
  padding: 2px 10px;
  text-align: left;
}
.serviceTable label{
  margin-left: 8px;

}
