.AdminsiparisItemContainer {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    border-left: 1px solid  #E0E0E0;
}

.adminListItemBox{
    padding: 10px 15px;
    /* border:1px solid  #E0E0E0; */
    border-bottom: 1px solid  #E0E0E0;
    border-right: 1px solid  #E0E0E0;
}

.adminListItemText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}

/* .adminSiparisEditIconDiv {
    width: 36px;
    padding: 5px;
    border:1px solid  #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
.adminSiparisIconDiv {
    width: 36px;
    padding: 5px;
    /* border:1px solid  #E0E0E0; */
    border-bottom: 1px solid  #E0E0E0;
    border-right: 1px solid  #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
/* 
  .adminSiparisIconDiv svg:hover{
    width: 14px;
  }

  .adminSiparisIconDiv img:hover{
    width: 10px;
  } */