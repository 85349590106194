/* The Modal (background) */
.modalAlert {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modalAlert-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .closemodalAlert {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .closemodalAlert:hover,
  .closemodalAlert:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modalAlert-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
  
  .modalAlert-body {padding: 2px 16px;}
  
  .modalAlert-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }