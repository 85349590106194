.loginBackground {
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
}

.loginContainer {
    /* width: 1200px; */
    height: 100%;
    display: flex;
    margin: auto;
    /* background: white; */
    justify-content: center;
    /* background-image: url('/assets/background.jpg') */
}

.loginFormBox {
    width: 500px;
    
    display: flex;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    border: 1px solid #2e2d2d;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
}

.loginTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #F2F2F2;
    margin-top: 20px;
    text-shadow: 1px 1px 2px black;
    text-align: center;
}

.loginGirisYap {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: #F2F2F2;
}

.loginFormParameterTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #BDBDBD;
    text-shadow: 1px 1px 2px black;
}

.loginFormContainer {
    width: 525px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.loginInputUserName {
    width: 100%;
    height: 48px;
    background: #F2F2F2;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
    padding-left: 10px;
}

.loginIletisim {
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    text-shadow: 1px 1px 2px black;
}
.iletisimLink:hover{
    color: #aeceff;
}
.iletisimLink{
    color: #ffffff;
}