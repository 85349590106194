.shareFormMain {
    padding: 10px;
  }

  .shareFormMiddleRow {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
  
  .shareFormMainInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .shareFormMainHeaders {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #4F4F4F;
  }
  
  .shareForm_SiparisTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #4F4F4F;
  }
  .shareFormShareMainHeaders{
    margin-top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #4F4F4F;
  }

  .shareFormTeamMembers {
    margin-top: 10px;
    padding: 8px 14px;
    border: 2px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: left;
  }

  .shareFormSubmitButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }