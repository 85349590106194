.siparisHeaderTitleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
}

.siparislerMainBanner {
    width: 100%;
}

.siparisPhoto {
    width: 384px;
    height: 210px;
}

.siparisItemContainer {
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 50px;
    width: 384px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}

.siparisItemWindow {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}

.siparisActions {
    display: flex;
    justify-content: space-between;
}

.siparisInfoContainer {
    padding: 24px;
    height: 300px;
    display: flex;
    justify-content: space-between;
    align-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
}

.siparisDashboardIdeaImage {
    Width: 13.5px;
    Height: 18px;
}

.siparisCurrentStep {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #BDBDBD;
}

.siparisTitle {
    margin-top: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #4F4F4F;
}

.siparisDescription {
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    word-wrap: break-word;
}

.siparisPaylas {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #828282;
}

.siparisIdeaCount {
    margin-left: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
}

.siparisListContainer {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    border-left: 1px solid #E0E0E0;
}

.siparisBodyContainer {
    background-color: #ffffff;
    width: 100%;
}

.siparisSearchCriteria {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #828282;
    padding: 5px;
    padding-left: 10px;
}

.siparisSearchComp {
    padding: 6px;
    display: flex;
    align-items: center;
}

.siparisSearchButton {
    padding: 6px;
    display: flex;
    padding-left: 10px;
}

.siparisGridHeader {

    display: flex;
    align-items: center;
    width: 1800px;
    height: 50px;
    margin: auto;

    background: #32C5FF;
    border-radius: 4px 4px 0px 0px;

}

.siparisGridHeaderItem {
    width: 150px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
    height: 100%;
    padding-top: 5px;
}

.siparisGridScroll {
    height: 60vh;
    overflow-x: scroll;
    cursor: default;
}

.siparisGridHeaderText {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height, or 150% */


    /* Grays/Gray 6 */

    color: #F2F2F2;

}

.siparisListItemBox {
    padding: 10px 15px;
    /* border:1px solid  #E0E0E0; */
    border-bottom: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
}

.siparisItemDate {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #828282;
}

.siparisStepDates {
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
}

.siparisGuncelleBlock {
    background: white;
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 20px;
}

.siparisPaginationContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}

.kayitBulunamadi {
    margin: 40px;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #828282;
    padding: 10px;
    padding-left: 10px;
}