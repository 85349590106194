.StepTitleContainerBox {
    width: 100%;
    height: 60px;
    background: #F2F2F2;
    border-radius: 4px;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
}

.StepTitleContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-left: 20px;
    margin-right: 20px;
}

.SetStepIdeaCurrentStepTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4F4F4F;
    padding-right: 20px;
    white-space: nowrap;
}

.SetStepIdeaTitleProgress {
    height: 8px;
    background: #828282;
    border-radius: 4px;
    width: 50%;
}

.SetStepIdeaNextStepTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #BDBDBD;
    padding-left: 20px;
    white-space: nowrap;
}

.SetStepIdeaHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    align-items: center;
}

.SetStepIdeaHeaderTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #4F4F4F;
}

.SetStepIdeaHeaderSortTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #606060;
    margin-right: 5px;
}

.downIcon {
    color: #828282;
}

.downIcon:hover {
    color: #4a4a4a;
}

.dropDownList {
    background: #F2F2F2;
    border-radius: 4px;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
}

.dropDownList:hover {
    background: #dadada;
}

.SetStepIdeaSelectContainer {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    border: 2px solid #F2F2F2;
    border-radius: 4px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.SetStepIdeaSelectIdeaTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #4F4F4F;
}

.SetStepIdeaSelectDate {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    margin-right: 20px;
}

.ideaSortOrder {
    margin-right: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
}