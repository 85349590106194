/* Tooltip container */
.tooltip {
    position: relative;
    /* display: inline-block; */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align:center;
    padding: 15px 15px;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 11;
    bottom: 125%;
    /* left: 50%; */
    /* margin-left: -60px; */
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    /* top: 100%;
    left: 50%; */
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }