.navbarContainer{
    padding: 20px;
    height: 80px;
    display: flex;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userNameLink{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
}
.userNameContainer{
    padding: 8px 8px;
}

.navBarLink {
    padding: 8px 18px;
    /* border: 2px solid #32C5FF; */
    border:none;
    box-sizing: border-box;
    border-radius: 4px;
    color: #32C5FF;
    background-color: white;
  
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  
  }
  
  .navBarLink:hover {
    border:none;
    /* 2px solid #0594b4;*/
    color: #0594b4;
  }