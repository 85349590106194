.siparisMain {
  padding: 10px;
}

.siparisMiddleRow {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.siparisMainInfo {
  width: 45%;
}

.siparisImage {
  width: 462px;
  height: 250px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.siparisMain p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

.siparisCheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.siparisCheckboxFont {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.siparisHeaderPhotoContainer {
  width: 948px;
  height: 277px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.siparisHeaderPhotoContainer:hover {
  cursor: pointer;
}

.siparisHeaderPhoto {
  width: 946px;
  height: 275px;
}

.siparisFoto {
  width: 462px;
  height: 250px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
}

.siparisPhotoUpload {
  height: 100px;
  width: auto;
}

.siparisPhotoUploadDescriptionTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisPhotoUploadDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisImagePhoto {
  width: 460px;
  height: 248px;
}

.siparisImage:hover {
  cursor: pointer;
}

.siparisSubmitButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.modalLoader {
  padding-top: 150px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.siparisStepContainer {
  margin-top: 24px;
  padding: 24px;
  display: flex;
  width: 100%;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.siparisStepFormLeft {
  width: 410px;
}

.siparisStepsTitle {
  margin-top: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.siparisStepsLine {
  margin-top: 10px;
  width: 100%;
  height: 3px;
  background: #E0E0E0;
}

.siparisStepListItem {
  width: 100%;
  height: 60px;
  background: #F2F2F2;
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}

.siparisStepItemTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #4F4F4F;
  margin-left: 25px;
}

.siparisStepItemDate {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #BDBDBD;
  padding-right: 25px;
}

.siparisStepListIconDiv {
  width: 30px;
  height: 30px;
  background-color: F2F2F2;
  display: flex;
  margin-right: 20px;
  background-color: F2F2F2;
  align-items: center;
  justify-content: center;
}

/* .siparisStepListIconDiv:hover {
  background-color: white;
}

.logo-img:hover {
  filter: invert(1);
  // or to blue
  // filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
} */