.paramBodyContainer {
    background-color: #ffffff;
    width: 100%;
    padding: 20px;
}

.parametreSecimTable table th {
    /* border: 1px solid var(--color-light); */
    border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding: 0.8rem;
    left: 0;
    margin: 0px;
}

.parametreSecimTable table tbody td {
    height: 2.8rem;
    padding: 0.8rem;
    /* border: 1px solid var(--color-light); */
    border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
}

.parametreSecimTable table tr td:first-child {
    text-align: left;
}

.formTable table th {
    color: var(--color-dark-variant);
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid var(--color-light);
    font-weight: 500;
    padding: 0.2rem;
    left: 0;
    margin: 0px;
}

.formTable table tbody td {
    height: 2.8rem;
    padding: 0.2rem;
    color: var(--color-dark-variant);
}

.formTable table tr td:first-child {
    text-align: left;
}