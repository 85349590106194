.adminSiparisContainer{
    width: 100%;
    min-height: 100vh;
    background-color: whitesmoke;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}

.adminBannerContainer{
    width: 100%;
    height: 100px;
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adminSiparisBannerTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-left: 20px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #333333;

}

.adminSiparisBannerDescription{

       
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    margin-left: 20px; 
    color: #828282;
}

.adminBannerContainerText{
    width: 59px;
    height: 24px;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    
    /* Grays/Gray 6 */
    
    color: #F2F2F2;

}
.adminContainerScroll{
    overflow-y: scroll;
    cursor: default;
    height: 500px;
}