.siparisIdeaMain {
  padding: 10px;
}

.siparisIdeaMiddleRow {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.siparisIdeaMainInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.siparisIdeaImage {
  width: 360px;
  height: 360px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.siparisIdeaMainHeaders {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

.siparisIdeaCheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.siparisIdeaCheckboxFont {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.siparisIdeaPhotoUpload {
  height: 100px;
  width: auto;
}

.siparisIdeaPhotoUploadDescriptionTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisIdeaPhotoUploadDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisIdeaImagePhoto {
  width: 358px;
  height: 358px;
}

.modalLoader {
  padding-top: 150px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.siparisIdeaAttachment {
  /* width: 242px;
  height: 62px; */

  padding: 8px 14px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.siparisIdeaAttachmentDosya {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.siparisIdeaAttachmentInfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #4F4F4F;
}

.siparisIdeaAttachmentRightContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.siparisIdeaAttachmentFileName {
  width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #4F4F4F;
}

.siparisIdeaSubmitButtons {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 24px; */
  margin-top: 100px;
}

.siparisIdeaTeamMembers {
  padding: 8px 14px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag-item {
  background-color: #e6e6e6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item>.button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.tag-item>.button:hover {
  color: white;
  background-color: #4F4F4F;
}

.RichTextEditorContainer {
  margin-top: 24px;
}

.SiparisIdea_SiparisTitle {
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #4F4F4F;
}

.editorWrapper {
  height: 250px;
}

.editorBody {
  padding: 5px;
  border: 1px solid #E0E0E0;
  background-color: rgba(221, 221, 221, 0.4);
}