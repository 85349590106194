.siparisIdeaDetailContainer {
  padding: 10px;
}


.siparisIdeaDetailAttachmentRightContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.siparisIdeaDetailAttachmentFileName {
  width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #828282;
}

.siparisIdeaDetailPhoto {
  width: 360px;
  height: 360px;
}

.siparisIdeaDetailRightSideBar {
  margin-left: 25px;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}

.siparisDetailSiparisTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #828282;
}

.siparisDetailIdeaTitle {
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #333333;
}

.siparisDetailIdeaOwner {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #5b5b5b;
}

.siparisIdeaDetailTeamMembers {
  margin-left: 10px;
  padding: 8px 0px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.siparisIdeaDetail-tag-item {
  background-color: #e6e6e6;
  font-size: 14px;
  border-radius: 20px;
  height: 30px;
  padding: 0 15px 0 15px;
  display: inline-flex;
  align-items: center;
  margin: 0.4rem 0.3rem 0 0;
}

.siparisIdeaMiddleRow {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.siparisIdeaMainInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.siparisIdeaDetailImage {
  width: 360px;
  height: 360px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.siparisIdeaDetailCommentsTitle {
  margin-top: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #4F4F4F;
}

.siparisIdeaDetailCommentLine {
  margin-top: 24px;
  width: 100%;
  height: 2px;
  background: #E0E0E0;
}

.siparisIdeaDetailCommentSeperatorLine {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background: #E0E0E0;
}

.siparisDetailCommentBox {
  width: 100%;
  height: 200px;
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
}

.IdeaCommentUserCircle {
  background-color: #BDBDBD;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.siparisIdeaDetailSubmitButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.siparisIdeaDetailCommentUserName {
  margin-left: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.siparisIdeaDetailComments {
  margin-top: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.ideaLikeCircle {
  position: absolute;
  right: -120px;
  background-color: white;
  width: 80px;
  height: 80px;
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.BacgroundBlue {
  background-color: #32C5FF;
}

.ideaLikeCircle:hover {
  background-color: rgb(172, 231, 255);
}

.siparisIdeaDetailActions {
  display: flex;
  justify-content: space-between;
}

.siparisDetailCountIcons {
  Width: 13.5px;
  Height: 18px;
}

.siparisIdeaCounts {
  margin-left: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #4F4F4F;
}