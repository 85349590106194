.tedarikci-email {
    margin-top: 10px;
    padding: 8px 14px;
    border: 2px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.tag-item {
    background-color: #e6e6e6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
}

.tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.tag-item>.button:hover {
    color: white;
    background-color: #4F4F4F;
}


.tedarikcilerBodyContainer {
    background-color: #ffffff;
    width: 100%;
    padding: 20px;
}



.tedarikciAttachment {
    /* width: 242px;
    height: 62px; */
  
    padding: 8px 14px;
    border: 2px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .tedarikciAttachmentDosya {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
  }
  
  .tedarikciAttachmentInfo {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4F4F4F;
  }
  
  .tedarikciAttachmentRightContainer {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .tedarikciAttachmentFileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #4F4F4F;
  }
  