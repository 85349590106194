.siparisIdeaItemContainer {
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 20px;
    width: 435px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
    /* border-radius: 4px; */
    display: flex;
    cursor: pointer;
}

.siparisIdeaPhotoContainer {
    width: 200px;
    height: 200px;
}

.siparisIdeaSiparisInfo {
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.siparisIdeaItemWindow {
    width: 435px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}

.siparisIdeaInfoContainer {
    padding: 16px;
    width: 225px;
    display: flex;
    justify-content: space-between;
    align-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
}

.siparisIdeaPhoto {
    width: 200px;
    height: 200px;
}

.siparisIdeaHeaderTitleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    bottom: 30px;
}

.siparisIdeaBannerTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 68px;
    align-items: center;
    color: #FFF;
    /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35); */
}

.siparisIdeaBannerText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #FFF;
    /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35); */
}

.siparisIdeaTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #4F4F4F;
}

.siparisIdeaDescription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    word-wrap: break-word;
}

.siparisIdeaActions {
    display: flex;
    justify-content: space-between;
}

.siparisIdeaRightSideContainer {
    margin-top: 20px;
}

.siparisIdeaSideBar {
    width: 250px;
    padding: 16px;
    background-color: white;
}


.siparisStatusText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
}

.siparisIdeaStepsLine {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: #E0E0E0;
}

.siparisSideBarStepsText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #BDBDBD;
}

.siparisSideBarIdeaDescription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #828282;
    word-break: break-word;
}

.currentStepColor {
    color: #4F4F4F;
}

.ideaSortOrderDashBoardContainer {
    margin-right: 50px;
    display: flex;
    flex-direction: row-reverse;
}

.ideaSortOrderDashBoard {
    margin-right: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
}

.ideaSortOrderDashBoardMenuItems {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFF;
}